/** defaults */
/* h1 {
	margin-top: 0.67em;
	margin-bottom: 0.67em;
} */

button {
	cursor: pointer;
}

body {
	font-size: 1rem;
}
