/**

 https://css-tricks.com/reboot-resets-reasoning/

*/

/* * {
	padding: 0;
	margin: 0;
} */

* {
	box-sizing: border-box;
	vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

ul,
li {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

a,
a:visited {
	text-decoration: none;
	color: initial;
}

button {
	background: none;
	border: none;
	margin: 0;
	padding: 0;
}
